<template>
  <modal :show.sync="modal">
    <form class="ml-3 mt-3 mr-3 mb-3">
      <h4 class="text-center">Alterar Número de Parcelas</h4>
      <p class="mt-1 text-center">A alteração terá efeito na(s) próxima(s) fatura(s).</p>
      <base-input label="Parcelas" required name="parcelas">
        <el-select v-model="newInstallment" filterable placeholder="Parcelas">
          <el-option v-for="installment in installments" :key="installment.value" :label="installment.label"
            :value="installment.value">
            {{ installment.label }}
          </el-option>
        </el-select>
      </base-input>
      <br class="clear" />
      <div class="col-md-12 text-center mt-3">
        <button type="button" class="btn btn-primary" @click="updateInstallments">Confirmar</button>
        <button type="button" class="btn btn-secundary ml-4" @click="close">Desistir</button>
      </div>
    </form>
  </modal>
</template>
<script>
import { Select, Option } from 'element-ui';

export default {
  name: 'subscription-change-installments-modal',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['modal', 'subscription', 'installments'],
  emits: ['onClose', 'onChange'],
  data() {
    return {
      newInstallment: null,
    }
  },
  mounted() {
    this.newInstallment = this.subscription.installments;
  },
  methods: {
    updateInstallments() {
      this.$clubApi.put(`/subscription/${this.subscription.id}/installments`, {
        installments: this.newInstallment
      }).then(() => {
        this.$notify({ type: 'success', message: `Operação realizada! As próximas faturas serão geradas com parcelamento em ${this.newInstallment} vez(es)` });
        this.close();
        this.$emit('onChange');
      }).catch((error) => {
        this.$notify({ type: 'warning', message: error.response.data.msg })
      }).finally(() => {
        NProgress.done();
      });
    },
    close() {
      this.$emit('onClose');
    },
  }
}
</script>